import React from 'react';

// mock data
const ClientData = {
  subscriptionDAte: '6/07/2019 (1 mois et 6jours)',
  telephoneNumber: '+216 54 378 399',
  registrationNumber: '--',
  note:
    'Ut vel posuere dui. In hac habitasse platea dictumst. Sed quis justo eleifend dui convallis cursus ac eget augue. Suspendisse sollicitudin.',
  history: 'La première fois',
};

// ***************************getters*****************************//
const getSiderInfoElement = (name, discription) => (
  <div>
    <div className="vortex-crm-general-info-info-block">
      <h4>{name}</h4>
      <p>{discription}</p>
    </div>
  </div>
);

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
const getClientInfo = (dataObject) => (
  <div>
    {getSiderInfoElement("Date d'inscription", dataObject.subscriptionDAte)}
    {getSiderInfoElement('Numéro du téléphone', dataObject.telephoneNumber)}
    {getSiderInfoElement('Numéro du carte grise', dataObject.registrationNumber)}
    {getSiderInfoElement('Note', dataObject.note)}
    {getSiderInfoElement("Historisue d'insctiption", dataObject.history)}
  </div>
);

function GeneralInfoView() {
  return <div className="vortex-crm-general-info">{getClientInfo(ClientData)}</div>;
}

export default GeneralInfoView;

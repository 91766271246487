// library imports
import React, { useState } from 'react';
import {
  Avatar, Table, Input, Icon, DatePicker,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Proptypes imports
import * as PropTypes from '../../common/proptypes';

// Components imports
import DemandListAssignedProviders from './DemandListAssignedProvivders';

// css imports
import './index.css';


/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
function DemandsList(props) {
  const { demands, providers } = props;
  const dateFormatList = ['DD/MM/YYYY'];
  const [setSearchText] = useState('');
  const [providerDetailsVisible, setProviderDetailsVisible] = useState(false);
  const [assignProvidersVisible, setAssignProvidersVisible] = useState(null);
  const [previewProvidersVisible, setPreviewProvidersVisible] = useState(null);

  const onDateChange = (date) => {
    return date;
  };

  // Header columns definition
  const getColumns = () => [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <h4 className="vortex-front-office-demands-table-client-name">{text}</h4>,
    },
    {
      title: 'VIN',
      dataIndex: 'vin',
      key: 'vin',
      render: (data) => (
        <div style={{ marginTop: 30 }}>
          {data.map((text) => (
            <h4 className="vortex-front-office-demands-table-client-vin">{text}</h4>
          ))}
        </div>
      ),
    },
    {
      title: 'Marque de la voiture',
      dataIndex: 'carBrand',
      key: 'carBrand',
      filters: [
        {
          text: 'Mercedes',
          value: 'Mercedes',
        },
        {
          text: 'BMW',
          value: 'BMW',
        },
        {
          text: 'Audi',
          value: 'Audi',
        },
      ],
      onFilter: (value, record) => record.carBrand === value,
      render: (data) => (
        <div style={{ marginTop: 30 }}>
          {data.map((text) => (
            <div>
              {text === '' ? (
                '--'
              ) : (
                <div className="vortex-front-office-demands-table-car-name">
                  <Avatar src={text.image} />
                  <Link to="/back-office/client-info">
                    <h4 className="vortex-front-office-demands-table-car-brandName">{text.name}</h4>
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Piéces de la voiture',
      dataIndex: 'carPieces',
      key: 'carPieces',
      render: (data) => (
        <div style={{ marginTop: 30 }}>
          {data.map((text) => (
            <div className="vortex-front-office-demands-table-client-car-pieces">{text}</div>
          ))}
        </div>
      ),
    },
    {
      title: 'Fournisseur assigner',
      dataIndex: 'assignedProviders',
      key: 'assignedProviders',
      render: (data, record) => (
        <DemandListAssignedProviders
          data={data}
          record={record}
          providers={providers}
          previewProvidersVisible={previewProvidersVisible}
          assignProvidersVisible={assignProvidersVisible}
          providerDetailsVisible={providerDetailsVisible}
          setProviderDetailsVisible={setProviderDetailsVisible}
          setAssignProvidersVisible={setAssignProvidersVisible}
          setPreviewProvidersVisible={setPreviewProvidersVisible}
        />
      ),
    },
  ];

  return (
    <div className="vortex-front-office-demands">
      <div className="vortex-front-office-demands-navigation">
        <h1>Listes des demandes</h1>
        <div>
          <DatePicker
            defaultValue={moment(new Date())}
            format={dateFormatList}
            onChange={onDateChange}
          />
          <Input
            placeholder="Recherche ici..."
            prefix={<Icon type="search" />}
            className="vortex-front-office-demands-navigation-search-input"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      <Table columns={getColumns()} dataSource={demands} />
    </div>
  );
}

DemandsList.propTypes = {
  demands: PropTypes.array,
  providers: PropTypes.array,
};

DemandsList.defaultProps = {
  demands: [],
  providers: [],
};

export default DemandsList;

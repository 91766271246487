import React, { useState } from 'react';
import { Layout, Button, Popover } from 'antd';

import CarFrame from '../../assets/carFrame.png';
import Frein from '../../assets/frein.png';

const { Content, Footer, Sider } = Layout;

const carsList = [
  {
    name: 'BMW CYELISÉ',
    number: '203344940303',
    pieces: [
      {
        pieceName: 'Disque de frein',
        model: 'XVV245',
        year: '2011',
        position: 'right-front-lamp',
        pieceImage: Frein,
      },
      {
        pieceName: 'Disque de frein',
        model: 'XVV243',
        year: '2016',
        position: 'right-back-light',
        pieceImage: Frein,
      },
      {
        pieceName: 'Disque de frein',
        model: 'XVV242',
        year: '2014',
        position: 'left-front-lamp',
        pieceImage: Frein,
      },
    ],
  },
  {
    name: 'CITROEN',
    number: '203344940303',
    pieces: [
      {
        pieceName: 'Bougie',
        model: 'BOSCH',
        year: '2015',
        position: 'motor',
        pieceImage: 'https://www.oreca-store.com/blog/wp-content/uploads/2018/06/bougie-ngk-iridium-ix.jpg',
      },
    ],
  },
  {
    name: 'MERCEDES',
    number: '203344940303',
    pieces: [
      {
        pieceName: 'Joint Du Culasse',
        model: 'XVV245',
        year: '2011',
        position: 'motor',
        pieceImage: 'https://cdn.pkwteile.de/uploads/360_photos/2099774/preview.jpg',
      },
      {
        pieceName: 'Disque de frein',
        model: 'XVV245',
        year: '2011',
        position: 'right-front-lamp',
        pieceImage: Frein,
      },
    ],
  },
  {
    name: 'OPEL',
    number: '203344940303',
    pieces: [
      {
        pieceName: 'Poussoire Hyrdraulique',
        model: 'XVV243',
        year: '2009',
        position: 'right-back-light',
        pieceImage: 'https://cdn.autoteiledirekt.de/thumb/assets/direkt/ersatz_categories/360x145/161.jpg',
      },
    ],
  },
];

/*  *************************** Helpers ************************ */
/**
 * this function searchs an array of objects for an item based  on it's name,
 *  and then returns the found element
 * @param {item} item to be looked for in the list
 * @param {array} list to be looked in for the item
 */
function findItem(item, list) {
  return list.find((el) => el.name === item);
}

/*  *************************** Getters ************************ */

const getSiderContent = (carPieces) => (
  <div>
    {carPieces.map((piece) => (
      <Button className="vortex-crm-car-pieces-sider-button">{piece}</Button>
    ))}
  </div>
);
const getPopoverContent = (el) => (
  <div className="vortex-crm-car-pieces-content-car-frame-item-popover">
    <img src={el.pieceImage} alt="car piece" />
    <h1>{el.pieceName}</h1>
    <p>
      Model
      {el.model}
    </p>
    <p>{el.year}</p>
  </div>
);

const getCarFrameItems = (car) => {
  return car.pieces.map((el) => (
    <div className={el.position}>
      <Popover content={getPopoverContent(el)}>
        <Button shape="circle" icon="tool" />
      </Popover>
    </div>
  ));
};

/* **************************** Component ********************** */
function CarPiecesView() {
  const [selectedCar, setSelectedCar] = useState(carsList[0].name);
  const selectedCarObject = findItem(selectedCar, carsList);
  const selectedPieces = selectedCarObject.pieces.map((el) => el.pieceName);

  const getCarSelector = (list) => {
    return list.map((car) => (
      <div
        className="vortex-crm-car-pieces-car-select-car-block"
        style={{ border: `${selectedCar === car.name ? '4px solid #502D90' : '1px solid #D7D4DB'}` }}
        onClick={() => setSelectedCar(car.name)}
        onKeyDown={() => setSelectedCar(car.name)}
        role="presentation"
      >
        <img src={CarFrame} alt="car" />
        <h1>{car.name}</h1>
        <p>{car.number}</p>
      </div>
    ));
  };

  return (
    <Layout className="vortex-crm-car-pieces">
      <Layout>
        <Sider className="vortex-crm-car-pieces-sider">{getSiderContent(selectedPieces)}</Sider>
        <Content className="vortex-crm-car-pieces-content">
          <div className="vortex-crm-car-pieces-content-car-frame" style={{ backgroundImage: `url(${CarFrame})` }}>
            {getCarFrameItems(selectedCarObject)}
          </div>
        </Content>
      </Layout>
      <Footer className="vortex-crm-car-pieces-car-select">{getCarSelector(carsList)}</Footer>
    </Layout>
  );
}

export default CarPiecesView;

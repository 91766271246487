import React from 'react';
import { Form, Input } from 'antd';
import * as PropTypes from '../../common/proptypes';

// image imports
import Car1 from '../../assets/car1.png';
import Car2 from '../../assets/car2.png';

// antd component
const { TextArea } = Input;

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
function ClientFormGeneralInfo(props) {
  const { getFieldDecorator, formData, setFormData } = props;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className="vortex-front-office-clientForm-clientInfo-form">
        <Form.Item required label="Nom du client">
          {getFieldDecorator('client-name', {
            rules: [{ required: true, message: 'Veuillez insérer le nom de client' }],
          })(<Input id="client-name" name="clientName" onChange={(event) => onInputChange(event)} />)}
        </Form.Item>
        <Form.Item required label="Numéro du télépohne">
          {getFieldDecorator('client-number', {
            rules: [{ required: true, message: 'Veuillez insérer le numéro de client' }],
          })(<Input id="client-number" name="clientNumber" onChange={(event) => onInputChange(event)} />)}
        </Form.Item>
        <Form.Item required label="Numéro carte grise">
          {getFieldDecorator('car-registration-number', {
            rules: [{ required: true, message: 'Veuillez insérer le numéro de la carte grise' }],
          })(
            <Input
              id="car-registration-number"
              name="vin"
              onChange={(event) => onInputChange(event)}
            />,
          )}
        </Form.Item>
        <Form.Item required label="Note">
          {getFieldDecorator('Note', {
            rules: [{ required: false, message: 'Ajouter des informations supplémentaires ' }],
          })(<TextArea id="Note" name="note" onChange={(event) => onInputChange(event)} />)}
        </Form.Item>
      </div>
      <div className="vortex-front-office-clientForm-clientInfo-ads">
        <img src={Car1} alt="" />
        <img src={Car2} alt="" />
      </div>
    </div>
  );
}

ClientFormGeneralInfo.propTypes = {
  getFieldDecorator: PropTypes.function,
  setFormData: PropTypes.function,
  formData: PropTypes.object,
};
ClientFormGeneralInfo.defaultProps = {
  getFieldDecorator: () => {},
  setFormData: () => {},
  formData: {},
};

export default ClientFormGeneralInfo;

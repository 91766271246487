import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DemandsListView from './DemandsListView';
import ClientForm from './ClientForm';

function FrontOfficeRouter() {
  return (
    <Switch>
      <Route exact path="/front-office/add-client" component={ClientForm} />
      <Route exact path="/front-office" component={DemandsListView} />
    </Switch>
  );
}

export default FrontOfficeRouter;

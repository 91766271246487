import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DemandDetailsView from './DemandsDetailsView';
import DemandsListView from './DemandsListView';

function ProviderRouter() {
  return (
    <Switch>
      <Route exact path="/provider/details" component={DemandDetailsView} />
      <Route exact path="/provider" component={DemandsListView} />
    </Switch>
  );
}

export default ProviderRouter;

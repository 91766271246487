import React, { useState } from 'react';
import {
  Button, Input, Badge, Avatar, Icon, Modal, Checkbox,
} from 'antd';
import * as PropTypes from '../../common/proptypes';


/* -------------------------------------------------------------------------- */
/*                               Main Component                               */
/* -------------------------------------------------------------------------- */
function DemandListAssignedProvivders(props) {
  const {
    record,
    providers,
    data,
    providerDetailsVisible,
    assignProvidersVisible,
    previewProvidersVisible,
    setPreviewProvidersVisible,
    setAssignProvidersVisible,
    setProviderDetailsVisible,
  } = props;
  const [setSearchText] = useState('');

  //   Demand list assigned providers modals
  const providerDetailsModal = (provider, providerData) => (
    <Modal
      visible={providerDetailsVisible}
      onCancel={() => setProviderDetailsVisible(false)}
      className="vortex-back-office-demands-table-assigned-providers-avatars-modal"
      footer={null}
    >
      <div>
        <div>
          <Badge dot style={{ backgroundColor: `${provider.online ? '#66D29E' : '#f5222d'}` }}>
            <Avatar src={provider.image} />
          </Badge>
          <h3>{provider.name}</h3>
          {providerData.responded ? (
            <Button className="vortex-back-office-demands-table-client-status-button-responded">
              <Icon type="check" style={{ transform: 'rotateY(180)' }} />
              1 réponse
            </Button>
          ) : (
            <Button className="vortex-back-office-demands-table-client-status-button-waiting">
              <Icon type="loading" />
              En attente
            </Button>
          )}
        </div>
        <Button shape="circle" icon="delete" />
      </div>
      <div className="vortex-back-office-demands-table-assigned-providers-avatars-modal-provider-info">
        <p>25ans</p>
        <p>Commercial chez Mercedes</p>
        <p>5 ans d expérience</p>
        <p>25 projets avec succés</p>
        <p>
          134 réponses dans 2019
        </p>
      </div>
    </Modal>
  );

  const previewProvidersModal = (agentsList, provideragentsList) => {
    return (
      <Modal
        className="vortex-back-office-demands-table-assigned-providers-avatars-preview-modal"
        visible={provideragentsList.id === previewProvidersVisible}
        onCancel={() => setPreviewProvidersVisible(null)}
        cancelText="Annuler"
        okText="Terminer"
      >
        <Input
          placeholder="Recherche ici..."
          prefix={<Icon type="search" />}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div>
          {agentsList.map((el) => (
            <div className="provider-wrapper">
              <Checkbox>
                <Avatar src={el.image} />
                <h3>{el.name}</h3>
              </Checkbox>
              <div className="preview-providers-modal-buttons">
                <Button>Assigné</Button>
                <Button className="vortex-back-office-demands-table-client-status-button-responded">
                  <Icon type="check" style={{ transform: 'rotateY(180)' }} />
                  1 réponse
                </Button>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    );
  };

  const assignProvidersModal = (providerData) => {
    return (
      <Modal
        className="vortex-back-office-demands-table-assigned-providers-avatars-assign-modal"
        visible={providerData.id === assignProvidersVisible}
        onCancel={() => setAssignProvidersVisible(null)}
        cancelText="Annuler"
        okText="Assigné"
      >
        <Input
          placeholder="Recherche ici..."
          prefix={<Icon type="search" />}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div>
          {providers.map((el) => (
            <Checkbox>
              <Avatar src={el.ProviderImage} />
              <h3>{el.ProviderName}</h3>
            </Checkbox>
          ))}
        </div>
      </Modal>
    );
  };

  return (
    <div className="vortex-back-office-demands-table-assigned-providers">
      <div>
        <Button className="vortex-front-office-demands-table-client-status-button-more">
          <Icon type="more" rotate={90} />
        </Button>
      </div>
      {data.length === 0 ? (
        record.carPieces.map(() => (
          <Button
            className="vortex-front-office-demands-table-client-status-button-assigne"
            onClick={() => setAssignProvidersVisible(record.id)}
          >
            Assingé un fournisseur
          </Button>
        ))
      ) : (
        <div>
          <div className="vortex-back-office-demands-table-assigned-providers-avatars">
            {data.slice(0, 3).map((el) => (
              <div>
                <Badge
                  dot
                  style={{ backgroundColor: `${el.online ? '#66D29E' : '#f5222d'}` }}
                  onClick={() => setProviderDetailsVisible(true)}
                >
                  <Avatar src={el.image} />
                </Badge>
                {providerDetailsModal(el, record)}
              </div>
            ))}
            {data.length > 3 ? (
              <Button onClick={() => setPreviewProvidersVisible(record.id)} shape="circle">
                {data.length - 3}
              </Button>
            ) : null}
            {previewProvidersModal(data, record)}
            <Button shape="circle" icon="plus" onClick={() => setAssignProvidersVisible(record.id)} />
          </div>
          {record.responded ? (
            <Button className="vortex-back-office-demands-table-client-status-button-responded">
              <Icon type="check" style={{ transform: 'rotateY(180)' }} />
              1 réponse
            </Button>
          ) : (
            <Button className="vortex-back-office-demands-table-client-status-button-waiting">
              <Icon type="loading" />
              En attente
            </Button>
          )}
        </div>
      )}
      {assignProvidersModal(data, record)}
    </div>
  );
}

DemandListAssignedProvivders.propTypes = {
  data: PropTypes.array,
  record: PropTypes.object,
  previewProvidersVisible: PropTypes.boolean,
  assignProvidersVisible: PropTypes.boolean,
  providerDetailsVisible: PropTypes.boolean,
  providers: PropTypes.array,
  setAssignProvidersVisible: PropTypes.func.isRequired,
  setPreviewProvidersVisible: PropTypes.func.isRequired,
  setProviderDetailsVisible: PropTypes.func.isRequired,
};

DemandListAssignedProvivders.defaultProps = {
  data: [],
  record: {},
  providers: [],
  previewProvidersVisible: false,
  assignProvidersVisible: false,
  providerDetailsVisible: false,
};

export default DemandListAssignedProvivders;

import React from 'react';

import { Spin, Typography } from 'antd';

import LogoImage from '../../assets/logo.png';

import styles from './LandingView.module.css';

const { Title } = Typography;

function LandingView() {
  return (
    <div className={styles.container}>
      <div className="vortex-logo">
        <img src={LogoImage} alt="logo" style={styles.logo} />
      </div>
      <Typography>
        <Title>Vortex</Title>
      </Typography>

      <Spin size="large" />
    </div>
  );
}

export default LandingView;

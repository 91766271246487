import React, { Component } from 'react';

import {
  Switch, Route, Redirect, withRouter, Link,
} from 'react-router-dom';

import { Layout, Button } from 'antd';
import { connect } from 'react-redux';
import * as PropTypes from '../../common/proptypes';

import FrontOfficeRouter from '../FrontOffice';
import ProviderRouter from '../Provider';
import BackOfficeRouter from '../BackOffice';
import CrmRouter from '../CRM';

import VortexLogo from '../../assets/logo.png';
import './index.css';

const withStore = connect((state) => ({
  user: state.Auth.user,
}));

const propTypes = {
  ...PropTypes.withRouting,
  ...PropTypes.withState,
};

const Wrapper = (C) => withRouter(withStore(C));

class Session extends Component {
  state = {};

  render() {
    const {
      location: { pathname },
    } = this.props;

    return (
      <Layout>
        <Layout.Header className="vortex-main-layout-header">
          <img src={VortexLogo} alt="" />
          {pathname.includes('/front-office') ? (
            <div>
              <Link to="/front-office">
                <Button shape="circle" icon="file-text" className="vortex-main-layout-buttons-file" />
              </Link>
              <Link to="/front-office/add-client">
                <Button shape="circle" icon="plus" className="vortex-main-layout-buttons-plus" />
              </Link>
            </div>
          ) : (
            ''
          )}
        </Layout.Header>
        <Layout.Content
          style={{
            background: '#f0f2f5',
            minHeight: 280,
          }}
        >
          <Switch>
            <Route path="/front-office" component={FrontOfficeRouter} />
            <Route path="/provider" component={ProviderRouter} />
            <Route path="/back-office" component={BackOfficeRouter} />
            <Route path="/crm" component={CrmRouter} />
            <Redirect from="/*" to="/front-office" />
          </Switch>
        </Layout.Content>
      </Layout>
    );
  }
}

Session.propTypes = propTypes;

export default Wrapper(Session);

// library imports
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Row, Col, Form, Button,
} from 'antd';
import _uniqueId from 'lodash/uniqueId';
import * as Dialog from '../Shared/Dialog';

// store imports
import { $createClientsDemand } from './state';

// component imports
import ClientFormGeneralInfo from './ClientFormGeneralInfo';
import CarFormItem from './CarFormItem';

// css imports
import './index.css';

const withStore = connect((state) => ({
  processing: state.Activity.processing,
}));

const Wrapper = (C) => withRouter(withStore(C));

// form data
const carPiecesFormData = {
  groupName: '',
  groupPieces: [],
};
const carFormData = {
  carBrand: '',
  carLogo: '',
  vin: '',
  carModel: '',
  carIdentifier: '',
  carPieces: [carPiecesFormData],
};
const initialFormData = {
  clientName: '',
  clientNumber: '',
  vin: '',
  note: '',
  clientCars: [carFormData],
};

let idx = 0;

/* ********************* Compoenent ******************** */
function ClientForm(props) {
  const { form, dispatch, history } = props;
  const { getFieldDecorator, getFieldValue } = form;
  const [formData, setFormData] = useState(initialFormData);
  const [id] = useState(_uniqueId('prefix-'));

  const getCarInfoFormItems = () => {
    const removeItem = (k) => {
      const keys = getFieldValue('keys');
      if (keys.length === 1) {
        return;
      }
      form.setFieldsValue({
        keys: keys.filter((key) => key !== k),
      });
      setFormData({
        ...formData,
        clientCars: formData.clientCars.filter((el, index) => index !== keys.indexOf(k)),
      });
    };

    const addItem = () => {
      const keys = getFieldValue('keys');
      idx += 1;
      const nextkeys = keys.concat(idx);

      form.setFieldsValue({
        keys: nextkeys,
      });
      setFormData({ ...formData, clientCars: [...formData.clientCars, carFormData] });
    };

    getFieldDecorator('keys', { initialValue: [0] });
    const keys = getFieldValue('keys');

    const onFormSubmit = () => {
      setFormData({ ...formData, id });

      dispatch($createClientsDemand(formData))
        .then(() => history.push('/front-office'))
        .catch((error) => Dialog.toast(Dialog.FAILURE, error.message));
    };

    return (
      <Col span={14} className="vortex-front-office-clientForm-carInfo">
        {keys.map((k, index) => (
          <CarFormItem
            addItem={addItem}
            removeItem={removeItem}
            k={k}
            keys={keys}
            index={index}
            formData={formData}
            setFormData={setFormData}
          />
        ))}
        <div className="vortex-front-office-clientForm-buttons">
          <Button className="vortex-front-office-clientForm-buttons-cancel">Annuler</Button>
          <Button onClick={() => onFormSubmit(formData)}>Soumettre</Button>
        </div>
      </Col>
    );
  };

  return (
    <div className="vortex-front-office-clientForm">
      <Form>
        <Row>
          <Col span={8} className="vortex-front-office-clientForm-clientInfo">
            <ClientFormGeneralInfo
              getFieldDecorator={getFieldDecorator}
              formData={formData}
              setFormData={setFormData}
            />
          </Col>
          {getCarInfoFormItems()}
        </Row>
      </Form>
    </div>
  );
}

ClientForm.propTypes = {
  ...withRouter,
  ...withStore,
};
const WrappedClientForm = Form.create({ name: 'ClientForm' })(ClientForm);

export default Wrapper(WrappedClientForm);

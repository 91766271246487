import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ClientsListView from './ClientsListView';
import ClientInforamation from './ClientInformation';

function CrmRouter() {
  return (
    <div className="vortex-crm">
      <Switch>
        <Route exact path="/crm/client-info" component={ClientInforamation} />
        <Route exact path="/crm" component={ClientsListView} />
      </Switch>
    </div>
  );
}

export default CrmRouter;

/**
 * Module name
 */

export const MODULE = 'Session';

/**
 * Initial state
 */

const INITIAL_STATE = {};

/**
 * Reducer
 */

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state;
  }
}

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DemandsListView from './DemandsListView';
import ClientDetails from './ClientDetails';

import './index.css';

function BackOfficeRouter() {
  return (
    <div className="vortex-back-office">
      <Switch>
        <Route exact path="/back-office/client-info" component={ClientDetails} />
        <Route exact path="/back-office" component={DemandsListView} />
      </Switch>
    </div>
  );
}

export default BackOfficeRouter;

// external libraries imports
import React from 'react';
import { Layout } from 'antd';

// component imports
import DemandsList from './DemandsList';

// images imports
import MercedesLogo from '../../assets/MercedesLogo.png';
import CitroenLogo from '../../assets/citroen.png';
import BMWLogo from '../../assets/BMWLogo.png';
import AudiLogo from '../../assets/audi.png';

// antd components
const { Content } = Layout;

/* -------------------------------- mock data ------------------------------- */
const demands = [
  {
    name: 'Mehdi Mahmoudi',
    vin: ['--', '2009AS05284', '--'],
    carBrand: [
      { name: 'Mercedes', image: MercedesLogo },
      { name: 'Citroen', image: CitroenLogo },
      { name: 'Audi', image: AudiLogo },
    ],
    carPieces: [
      'Plaquette de frein, Disque frein,Plaquette de frein',
      'Plaquette de frein, Disque frein,Plaquette de frein',
      'Plaquette de frein Plaquette de frein',
    ],
    update: ['31/5/2018', '31/5/2018', '31/5/2018'],
    statusCompleted: [false, true, false],
  },
  {
    name: 'Andy Jackson',
    carBrand: [{ name: 'BMW', image: BMWLogo }],
    vin: ['2009AS05284'],
    carPieces: ['frein'],
    update: ['31/5/2018'],
    statusCompleted: [true],
  },
  {
    name: 'Aous Michel',
    carBrand: [{ name: 'Mercedes', image: MercedesLogo }],
    vin: ['2009AS05284'],
    carPieces: ['frein'],
    update: ['31/5/2018'],
    statusCompleted: [false],
  },
];

function DemandsListView() {
  return (
    <Content>
      <DemandsList demands={demands} />
    </Content>
  );
}

export default DemandsListView;

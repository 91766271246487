import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import DemandsList from './DemandsList';
import * as PropTypes from '../../common/proptypes';
import * as Dialog from '../Shared/Dialog';
import { $fetchProviders } from './state';
import MercedesLogo from '../../assets/MercedesLogo.png';
import CitroenLogo from '../../assets/citroen.png';
import BMWLogo from '../../assets/BMWLogo.png';
import AudiLogo from '../../assets/audi.png';

const withStore = connect((state) => ({
  processing: state.Activity.processing,
  providers: state.BackOffice.providers,
}));

const { Content } = Layout;

const Wrapper = (C) => withStore(C);

const demands = [
  {
    name: 'Jessie Kelly',
    vin: ['--', '2009AS05284', '--'],
    carBrand: [
      { name: 'Mercedes', image: MercedesLogo },
      { name: 'Citroen', image: CitroenLogo },
      { name: 'Audi', image: AudiLogo },
    ],
    carPieces: [
      'Plaquette de frein, Disque frein,Plaquette de frein',
      'Disque frein,Plaquette de frein',
      'Joint Culasse, Bougie',
    ],
    update: ['31/5/2018', '31/5/2018', '31/5/2018'],
    assignedProviders: [],
    id: 1,
  },
  {
    name: 'Chris Thomas',
    vin: ['2009sqddf5284'],
    carBrand: [{ name: 'BMW', image: BMWLogo }],
    carPieces: ['frein, Bougie'],
    update: ['31/5/2018'],
    responded: false,
    assignedProviders: [
      {
        image: 'https://randomuser.me/api/portraits/men/90.jpg',
        name: 'Aous Michel',
        response: {
          available: [
            {
              pieceName: 'frein',
              price: '1255 DT',
              status: 'Original',
              comparison: '+ 25 de Bernard Mark',
            },
            {
              pieceName: 'joint de culasse',
              price: '310 DT',
              status: 'Moyen gamme',
              comparison: '-140 DT de Fernard Sig',
            },
          ],
          nonAvailable: ['Poussoir', 'Bougie'],
          exists: true,
        },
        online: true,
        id: 1,
      },
      {
        image: 'https://randomuser.me/api/portraits/men/79.jpg',
        name: 'Andy Jackson',
        response: {
          available: ['frein', 'jointde culasse'],
          nonAvailable: ['Poussoir hydraulique', 'Bougie'],
          exists: false,
        },
        online: false,
        id: 2,
      },
      {
        image: 'https://randomuser.me/api/portraits/men/87.jpg',
        name: 'Chris Thomas',
        response: {
          available: [
            {
              pieceName: 'frein',
              price: '1255 DT',
              status: 'Original',
              comparison: '+ 25 de Bernard Mark',
            },
            {
              pieceName: 'joint de culasse',
              price: '310 DT',
              status: 'Moyen gamme',
              comparison: '-140 DT de Fernard Sig',
            },
          ],
          nonAvailable: ['Poussoir', 'Bougie'],
          exists: true,
        },
        online: true,
        id: 3,
      },
      {
        image: 'https://randomuser.me/api/portraits/men/76.jpg',
        name: 'Michel Veins',
        response: {
          available: ['frein', 'jointde culasse'],
          nonAvailable: ['Poussoir hydraulique', 'Bougie'],
          exists: false,
        },
        online: false,
      },
    ],
    id: 2,
  },
  {
    name: 'Andy Jackson',
    vin: ['--'],
    carBrand: [{ name: 'Mercedes', image: MercedesLogo }],
    carPieces: ['frein'],
    update: ['31/5/2018'],
    responded: true,
    assignedProviders: [
      {
        image: 'https://randomuser.me/api/portraits/men/76.jpg',
        name: 'Aous Michel',
        response: {
          available: [
            {
              pieceName: 'frein',
              price: '1255 DT',
              status: 'Original',
              comparison: '+ 25 de Bernard Mark',
            },
            {
              pieceName: 'joint de culasse',
              price: '310 DT',
              status: 'Moyen gamme',
              comparison: '-140 DT de Fernard Sig',
            },
          ],
          nonAvailable: ['Poussoir', 'Bougie'],
          exists: true,
        },
        online: true,
      },
      {
        image: 'https://randomuser.me/api/portraits/men/74.jpg',
        name: 'Aous Michel',
        response: {
          available: ['frein', 'jointde culasse'],
          nonAvailable: ['Poussoir hydraulique', 'Bougie'],
          exists: false,
        },
        online: false,
      },
    ],
    id: 3,
  },
];

function DemandsListView(props) {
  const { dispatch, providers } = props;

  // This is when the data isn't ready yet (REQUEST) or in case of a fetch failure
  const validateProviders = Array.isArray(providers) ? providers : [];

  useEffect(() => {
    dispatch($fetchProviders()).catch((error) => Dialog.toast(Dialog.FAILURE, error.message));
  }, []);

  return (
    <Content>
      <DemandsList demands={demands} providers={validateProviders} />
    </Content>
  );
}

DemandsListView.propTypes = {
  ...PropTypes.withRouting,
  ...PropTypes.withState,
};

export default Wrapper(DemandsListView);

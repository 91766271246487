// libraries imports
import React, { useState } from 'react';
import {
  Form, Button, Select, Input,
} from 'antd';
import * as PropTypes from '../../common/proptypes';

// components imports
import CarPiecesFormItem from './CarPiecesFormItem';

// image imports
import MercedesLogo from '../../assets/MercedesLogo.png';
import CitroenLogo from '../../assets/citroen.png';
import BMWLogo from '../../assets/BMWLogo.png';
import AudiLogo from '../../assets/audi.png';

// antd imports
const InputGroup = Input.Group;
const { Option } = Select;

// Mock data
const cars = [
  { brand: 'Mercedes', logo: MercedesLogo, models: ['A-Class', 'AMG GT', 'C-Class'] },
  { brand: 'Audi', logo: AudiLogo, models: ['S', 'TT', 'Q', 'RS', 'R'] },
  { brand: 'BMW', logo: BMWLogo, models: ['Série 1', 'Série 2', 'Série 3', 'I', 'M'] },
  { brand: 'Citroen', logo: CitroenLogo, models: ['2CV', 'AX', 'Berlingo', 'BX'] },
];
const carPiecesFormData = {
  groupName: '',
  groupPieces: [],
};

/* ********************* Compoenent ******************** */
function CarFormItem(props) {
  const {
    addItem, removeItem, k, index, form, formData, setFormData, keys,
  } = props;
  const { getFieldDecorator } = form;
  const [selectedBrand, setSelectedBrand] = useState({
    brand: 'Mercedes',
    logo: MercedesLogo,
    models: ['A-Class', 'AMG GT', 'C-Class'],
  });
  const onSelectBrand = (value, id) => {
    const selectedCar = cars.find((car) => car.brand === value);
    setSelectedBrand(selectedCar);
    setFormData({
      ...formData,
      clientCars: formData.clientCars.map((el, idx) => {
        return idx === keys.indexOf(id)
          ? {
            ...el,
            carBrand: value,
            carLogo: selectedCar.logo,
            close: false,
          }
          : el;
      }),
    });
  };
  const onSelectModel = (value, id) => {
    setFormData({
      ...formData,
      clientCars: formData.clientCars.map((el, idx) => {
        return idx === keys.indexOf(id) ? { ...el, carModel: value } : el;
      }),
    });
  };
  const onSelectIdentifier = (value, id) => {
    setFormData({
      ...formData,
      clientCars: formData.clientCars.map((el, idx) => {
        return idx === keys.indexOf(id) ? { ...el, carIdentifier: value } : el;
      }),
    });
  };
  const onVinChange = (e, id) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      clientCars: formData.clientCars.map((el, idx) => {
        return idx === keys.indexOf(id) ? { ...el, vin: value } : el;
      }),
    });
  };

  return (
    <div key={k}>
      <div className="vortex-front-office-clientForm-carInfo-buttons">
        <Button icon="minus" className="add-remove-button" onClick={() => removeItem(k)} />
        <Button
          icon="plus"
          className="add-remove-button"
          onClick={addItem}
          style={{ display: `${index !== 0 ? 'none' : 'block'}` }}
        />
      </div>
      <Form.Item required label="La marque de la voiture">
        {getFieldDecorator('car-brand', {
          rules: [{ required: true, message: 'Choisissez la marque de la voiture' }],
        })(
          <InputGroup>
            <Select
              defaultValue={selectedBrand.brand}
              onChange={(value) => onSelectBrand(value, k)}
            >
              {cars.map((car) => (
                <Option key={car.brand}>
                  <img className="brand-logo-select" src={car.logo} alt={car.breand} />
                  {car.brand}
                </Option>
              ))}
            </Select>
            <Select
              defaultValue={selectedBrand.models[0]}
              onChange={(value) => onSelectModel(value, k)}
            >
              {selectedBrand.models.map((model) => (
                <Option key={model}>{model}</Option>
              ))}
            </Select>
            <Select defaultValue="Serie 1" onChange={(value) => onSelectIdentifier(value, k)}>
              <option value="Serie2">Serie2</option>
              <option value="Serie 1">Serie 1</option>
              <option value="M2">M2</option>
              <option value="E250">E250</option>
            </Select>
          </InputGroup>,
        )}
      </Form.Item>
      <Form.Item required label="VIN">
        {getFieldDecorator('car-vin', {
          rules: [{ required: false, message: 'Saisissez le vin de la voiture' }],
        })(<Input name="vin" onChange={(e) => onVinChange(e)} />)}
      </Form.Item>
      <CarPiecesFormItem
        formItemData={carPiecesFormData}
        formData={formData}
        setFormData={setFormData}
        k={k}
        keys={keys}
      />
      <div className="vortex-front-office-clientForm-carInfo-seperating-line" />
    </div>
  );
}

CarFormItem.propTypes = {
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  k: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired,
};

const WrappedCarFormItem = Form.create({ name: 'CarItem' })(CarFormItem);
export default WrappedCarFormItem;

export function CreateFileFromUrl(url, fileName, fileType) {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const objectURL = URL.createObjectURL(blob);
      const file = File([blob], fileName, { type: fileType });
      file.src = objectURL;
      return file;
    });
}

export * from 'helloworld-lib/src/common/fetch.helper';

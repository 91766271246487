import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar, Table, Button, Icon, Layout, Badge,
} from 'antd';

import BMWLogo from '../../assets/BMWLogo.png';
import './index.css';

const { Header, Content, Sider } = Layout;

// ***************************Mock data*****************************//
const infoData = {
  subscriptionDAte: '6/07/2019 (1 mois et 6jours)',
  telephoneNumber: '+216 54 378 399',
  registrationNumber: '--',
  note:
    'Ut vel posuere dui. In hac habitasse platea dictumst. Sed quis justo eleifend dui convallis cursus ac eget augue. Suspendisse sollicitudin.',
  history: 'La première fois',
};

const carInfo = [
  {
    carName: {
      carLogo: BMWLogo,
      carBrand: 'BMW',
      carModel: 'Serie 1 / 1 (E87) 022',
    },
    carPieces:
      'Disques de frein, Plaquettes de frein, Joint de culasse, Poussoir hydraulique, Disques de frein, Plaquettes de frein, Joint de culasse, Poussoir hydraulique',
  },
];

const providers = [
  {
    image: 'https://randomuser.me/api/portraits/men/74.jpg',
    name: 'Aous Michel',
    response: {
      available: [
        {
          pieceName: 'frein',
          price: '1255 DT',
          status: 'Original',
          comparison: '+ 25 de Bernard Mark',
        },
        {
          pieceName: 'joint de culasse',
          price: '310 DT',
          status: 'Moyen gamme',
          comparison: '-140 DT de Fernard Sig',
        },
      ],
      nonAvailable: ['Poussoir', 'Bougie'],
      exists: true,
    },
    online: true,
  },
  {
    image: 'https://randomuser.me/api/portraits/men/76.jpg',
    name: 'Chris Thomas',
    response: {
      available: ['frein', 'jointde culasse'],
      nonAvailable: ['Poussoir hydraulique', 'Bougie'],
      exists: false,
    },
    online: false,
  },
];

// ***************************getters*****************************//
const getSiderInfoElement = (name, discription) => (
  <div>
    <div className="vortex-back-office-client-details-sider-info-block">
      <h4>{name}</h4>
      <p>{discription}</p>
    </div>
  </div>
);

const getSiderInfoBlock = (dataObject) => (
  <div>
    {getSiderInfoElement("Date d'inscription", dataObject.subscriptionDAte)}
    {getSiderInfoElement('Numéro du téléphone', dataObject.telephoneNumber)}
    {getSiderInfoElement('Numéro du carte grise', dataObject.registrationNumber)}
    {getSiderInfoElement('Note', dataObject.note)}
    {getSiderInfoElement("Historisue d'insctiption", dataObject.history)}
  </div>
);

const getColumns = () => [
  {
    title: 'Marque de la voiture',
    dataIndex: 'carName',
    key: 'carName',
    render: (data) => (
      <div className="vortex-back-office-client-details-header-table-car-name">
        <Avatar src={data.carLogo} />
        <div>
          <h4>{data.carBrand}</h4>
          <p>{data.carModel}</p>
        </div>
      </div>
    ),
  },
  {
    title: 'Pièce de la voiture',
    dataIndex: 'carPieces',
    key: 'carPieces',
    render: (data) => <div>{data}</div>,
  },
];

// *************************** Component ***************************//

function ClientDetails() {
  const [providerDetailsVisible, setProviderDetailsVisible] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('');

  const onCloseProvidedPieces = () => {
    setProviderDetailsVisible(false);
  };
  const onSelectProvider = (provider) => {
    setSelectedProvider(provider);
    setProviderDetailsVisible(true);
  };

  const getProvidersResponses = (provider) => (
    <div className="vortex-back-office-client-detials-content-providers-responses">
      <Badge dot style={{ backgroundColor: `${provider.online ? '#66D29E' : 'red'}` }}>
        <Avatar src={provider.image} />
      </Badge>
      <div onClick={() => onSelectProvider(provider)} onKeyDown={() => onSelectProvider(provider)} role="presentation">
        {provider.name}
      </div>
      {!provider.response.exists ? (
        <Button className="no-response">Pas de réponses</Button>
      ) : (
        <div>
          <Button className="available" onClick={() => onSelectProvider(provider)}>
            {provider.response.available.length}
            &nbsp; Piéces Disponible
          </Button>
          <Button className="non-available" onClick={() => onSelectProvider(provider)}>
            {provider.response.nonAvailable.length}
            &nbsp; Piéces non Disponible
          </Button>
        </div>
      )}
    </div>
  );

  const getProvidedPieces = (provider) => (
    <div className="vortex-back-office-client-detials-content-provider-response">
      <div>
        <div className="vortex-back-office-client-detials-content-providers-responses">
          <Badge dot style={{ backgroundColor: `${provider.online ? '#66D29E' : 'red'}` }}>
            <Avatar src={provider.image} />
          </Badge>
          <p>{provider.name}</p>
        </div>
        <div className="vortex-back-office-client-detials-content-provider-response-icons">
          <Icon type="printer" />
          <Icon type="close" onClick={() => onCloseProvidedPieces()} />
        </div>
      </div>

      <table>
        {provider.response.available.map((el) => (
          <tr>
            <td>{el.pieceName}</td>
            <td>{el.price}</td>
            <td>{el.status}</td>
            <td>{el.comparison}</td>
          </tr>
        ))}
        {provider.response.nonAvailable.map((el) => (
          <tr className="non-available-pieces">
            <td>{el}</td>
          </tr>
        ))}
      </table>
    </div>
  );

  return (
    <div className="vortex-back-office-client-details">
      <div className="vortex-back-office-clients-details-navigation">
        <div>
          <h1>Mehdi Mahmoudi</h1>
          <h3> 21/08/2019</h3>
        </div>
        <div>
          <Link to="/back-office">
            <Icon type="arrow-left" className="vortex-provider-demand-details-back-arrow" />
          </Link>
        </div>
      </div>
      <Layout>
        <Sider className="vortex-back-office-client-details-sider">{getSiderInfoBlock(infoData)}</Sider>
        <Layout>
          <Header className="vortex-back-office-client-details-header">
            <Table columns={getColumns()} dataSource={carInfo} />
          </Header>
          <Content className="vortex-back-office-client-details-content">
            {providerDetailsVisible ? (
              getProvidedPieces(selectedProvider)
            ) : (
              <div>
                <div className="vortex-back-office-client-detials-content-title">
                  Réponses
                  <span>
                    {"(3 réponses aujourd'hui)"}
                  </span>
                </div>
                <div>{providers.map((provider) => getProvidersResponses(provider))}</div>
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default ClientDetails;

import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';

// Localization
import intl from 'react-intl-universal';

import * as PropTypes from './common/proptypes';

import * as Logger from './common/logger';

import LandingView from './topics/Shared/LandingView';
import Session from './topics/Session';
import Entrance from './topics/Entrance/index';

// Localization -- Common data used by react-intl-universal
require('intl/locale-data/jsonp/en.js');

const withStore = connect((state) => ({
  appReady: state.Shared.appReady,
  appInSession: state.Shared.appInSession,
  authenticated: state.Auth.authenticated,
}));

const propTypes = {
  ...PropTypes.withState,
  appReady: PropTypes.bool.isRequired,
  appInSession: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

// Combine locales
// const Main_fr_FR = require('./locales/fr-FR.json');
// const Account_fr_FR = require('./locales/fr-FR/Account/Account.json');
// const Account__Registration_fr_FR = require('./locales/fr-FR/Account/Registration/Registration.json');
// const SideMenu_fr_FR = require('./locales/fr-FR/SideMenu.json');

// Specific app locales
const locales = {
  'en-US': require('./locales/en-US.json'),
  'fr-FR': require('./locales/fr-FR.json'),
};

const Wrapper = (C) => withStore(C);

class App extends Component {
  state = { localesReady: false };

  componentDidMount() {
    this.loadLocales();
  }

  componentDidCatch(error, info) {
    Logger.error(error, info);
  }

  loadLocales() {
    // Load France, French as the default locale
    intl
      .init({
        currentLocale: 'fr-FR',
        locales,
      })
      .then(() => {
        this.setState({ localesReady: true });
      });
  }

  render() {
    const { appReady, appInSession, authenticated } = this.props;
    const { localesReady } = this.state;

    if (localesReady) {
      // if (!appReady || (authenticated && !appInSession)) {
      //   return <LandingView />;
      // }

      // return authenticated ? <Session /> : <Entrance />;
      return <Session />;
    }
    return null;
  }
}

App.propTypes = propTypes;

export default Wrapper(App);

// library imports
import React from 'react';
import {
  Form, Input, Icon, Button, Select,
} from 'antd';
import * as PropTypes from '../../common/proptypes';

// antd component
const InputGroup = Input.Group;
const { Option } = Select;

// const declaration
let id = 0;

/* ********************* Compoenent ******************** */
function CarPiecesFormItem(props) {
  const {
    form, formItemData, formData, setFormData, keys, k,
  } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const removePieceFormItem = (l, j) => {
    const piecesKeys = getFieldValue('piecesKeys');
    if (piecesKeys.length === 1) {
      return;
    }
    form.setFieldsValue({
      piecesKeys: piecesKeys.filter((key) => key !== j),
    });
    setFormData({
      ...formData,
      clientCars: formData.clientCars.map((el, idx) => {
        return keys.indexOf(l) === idx
          ? {
            ...el,
            carPieces: el.carPieces.filter((x, index) => index !== piecesKeys.indexOf(j)),
          }
          : el;
      }),
    });
  };

  const addPieceFormItem = () => {
    const piecesKeys = getFieldValue('piecesKeys');
    id += 1;
    const nextpiecesKeys = piecesKeys.concat(id);

    form.setFieldsValue({
      piecesKeys: nextpiecesKeys,
    });
    setFormData({
      ...formData,
      clientCars: formData.clientCars.map((el, index) => {
        return index === keys.indexOf(k)
          ? { ...el, carPieces: el.carPieces.concat(formItemData) } : el;
      }),
    });
  };

  const onGroupNameChange = (e, l, j) => {
    const piecesKeys = getFieldValue('piecesKeys');

    setFormData({
      ...formData,
      clientCars: formData.clientCars.map((el, index) => {
        return index === keys.indexOf(l)
          ? {
            ...el,
            carPieces: el.carPieces.map((element, i) => {
              return i === piecesKeys.indexOf(j) ? { ...element, groupName: e } : element;
            }),
          }
          : el;
      }),
    });
  };
  const onGroupPiecesChange = (e, l, j) => {
    const piecesKeys = getFieldValue('piecesKeys');
    setFormData({
      ...formData,
      clientCars: formData.clientCars.map((el, index) => {
        return index === keys.indexOf(l)
          ? {
            ...el,
            carPieces: el.carPieces.map((element, key) => {
              return key === piecesKeys.indexOf(j) ? { ...element, groupPieces: e } : element;
            }),
          }
          : el;
      }),
    });
  };

  getFieldDecorator('piecesKeys', { initialValue: [0] });
  const piecesKeys = getFieldValue('piecesKeys');
  return (
    <div>
      {piecesKeys.map((j, index) => (
        <Form.Item required label={index === 0 ? 'Pièces de voiture' : ''} key={j}>
          {getFieldDecorator(`car-piece[${j}]`, {
            rules: [{ required: true, message: 'Choisissez les pièces endommagé' }],
          })(
            <InputGroup>
              <Select placeholder="Choisissez le groupe" onChange={(e) => onGroupNameChange(e, k, j)}>
                <Option value="freinage">Freinage</Option>
                <Option value="moteur">Moteur</Option>
                <Option value="pneu">Pneu</Option>
                <Option value="carcasse">Carcasse</Option>
              </Select>
              <Select
                mode="multiple"
                size="large"
                placeholder="Choisissez les pièces endommagé ..."
                defaultValue={[]}
                onChange={(e) => onGroupPiecesChange(e, k, j)}
              >
                <Option value="Disques de frein">Disques de frein</Option>
                <Option value="Plaquettes de frein">Plaquettes de frein</Option>
                <Option value="Garniture">Garniture</Option>
                <Option value="Flasque">Flasque</Option>
              </Select>
            </InputGroup>,
          )}
          {piecesKeys.length > 1 ? (
            <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => removePieceFormItem(k, j)} />
          ) : null}
        </Form.Item>
      ))}
      <Button icon="plus" className="add-remove-button add-piece-item" onClick={addPieceFormItem} />
    </div>
  );
}

CarPiecesFormItem.propTypes = {
  formItemData: PropTypes.object.isRequired,
  k: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired,
};
const WrappedCarPiecesFormItem = Form.create({ name: 'CarPiecesItem' })(CarPiecesFormItem);
export default WrappedCarPiecesFormItem;

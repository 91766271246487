import React from 'react';
import {
  Avatar, Table, Button, Input, Select, Icon,
} from 'antd';
import { Link } from 'react-router-dom';

// image imports
import BMWLogo from '../../assets/BMWLogo.png';

// style import
import './index.css';

// antd component
const { Option } = Select;

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
function DemandsDetailsView() {
  const demands = [
    {
      carName: 'BMW',
      carPiece: ['Disques de frein', 'Plaquettes de frein', 'Joint de culasse', 'Bougie', 'Poussoire hydraulique'],
    },
  ];

  // Header columns definition
  const getColumns = () => [
    {
      title: 'Pièces de la voiture',
      dataIndex: 'carPiece',
      key: 'carPiece',
      render: (data) => (
        <div className="vortex-provider-details-table-pieceName">
          {data.map((text) => (
            <h4>{text}</h4>
          ))}
        </div>
      ),
    },
    {
      title: 'Disponibilité',
      dataIndex: 'disponibility',
      key: 'disponibility',
      render: (data, record) => (
        <div className="vortex-provider-details-table-disponibility">
          {record.carPiece.map(() => (
            <Select defaultValue="NonDisponible">
              <option value="NonDisponible">NonDisponible</option>
              <option value="Original">Original</option>
              <option value="NonOriginal">Non Original</option>
            </Select>
          ))}
        </div>
      ),
    },
    {
      title: 'Prix',
      dataIndex: 'price',
      key: 'price',
      render: (data, record) => (
        <div className="vortex-provider-details-table-price">
          {record.carPiece.map(() => (
            <Input />
          ))}
        </div>
      ),
    },
    {
      title: 'Original',
      dataIndex: 'original',
      key: 'original',
      render: (data, record) => (
        <div className="vortex-provider-details-table-original">
          {record.carPiece.map(() => (
            <Select defaultValue="X-2R78">
              <option value="X-2R78">X-2R78</option>
              <option value="X-2XWk">X-2XWk</option>
              <option value="Z-100K">Z-100K</option>
            </Select>
          ))}
        </div>
      ),
    },
    {
      title: 'Adaptable',
      dataIndex: 'adaptable',
      key: 'adaptable',
      render: (data, record) => (
        <div className="vortex-provider-details-table-adaptable">
          {record.carPiece.map(() => (
            <Select
              mode="multiple"
              size="large"
              placeholder="Choisissez les pièces endommagé ..."
              defaultValue={[]}
            >
              <Option value="A-288">A-288</Option>
              <Option value="HJIJIERG">HJIJIERG</Option>
              <Option value="IHGIRHG398R">IHGIRHG398R</Option>
              <Option value="A-288">A-288</Option>
            </Select>
          ))}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'statusCompleted',
      key: 'statusCompleted',
      render: (data, record) => (
        <div className="vortex-provider-details-table-buttons">
          {record.carPiece.map(() => (
            <Button className="vortex-provider-demand-details-table-responde-botton">Repondre</Button>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div className="vortex-front-office-demands">
      <div className="vortex-front-office-demands-navigation">
        <div>
          <Avatar src={BMWLogo} />
          <h1>{demands[0].carName}</h1>
        </div>
        <div>
          <Link to="/provider">
            <Icon type="arrow-left" className="vortex-provider-demand-details-back-arrow" />
          </Link>
        </div>
      </div>
      <Table columns={getColumns()} dataSource={demands} />
    </div>
  );
}

export default DemandsDetailsView;

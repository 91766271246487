// react and other libraries imports
import React, { useState } from 'react';
import {
  Avatar, Table, Input, Icon, DatePicker,
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

// Proptypes import
import * as PropTypes from '../../common/proptypes';

// style import
import './index.css';

function ClientsList(props) {
  const { clients } = props;
  const dateFormatList = ['DD/MM/YYYY'];
  const [setSearchText] = useState('');
  const onDateChange = (date) => {
    return date;
  };

  // Header columns definition
  const getColumns = () => [
    {
      title: 'Profile',
      dataIndex: 'client',
      key: 'client',
      render: (data) => (
        <div className="vortex-crm-clients-table-client-name">
          <Avatar size={64} src={data.image} />
          <Link to="/crm/client-info">
            <h4 className="vortex-crm-clients-table-row-title">{data.name}</h4>
          </Link>
        </div>
      ),
    },
    {
      title: 'Numéro carte grise',
      dataIndex: 'registrationNumber',
      key: 'registrationNumber',
      render: (data) => <h4 className="vortex-crm-clients-table-row-title">{data}</h4>,
    },
    {
      title: 'information',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: "Date d'ajout",
      dataIndex: 'addingDate',
      key: 'addingDate',
      render: (data) => <h4 className="vortex-crm-clients-table-row-title">{data}</h4>,
    },
  ];

  return (
    <div className="vortex-crm">
      <div className="vortex-front-office-demands-navigation">
        <h1>Listes des demandes</h1>
        <div>
          <DatePicker
            defaultValue={moment(new Date())}
            format={dateFormatList}
            onChange={onDateChange}
          />
          <Input
            placeholder="Recherche ici..."
            prefix={<Icon type="search" />}
            className="vortex-front-office-demands-navigation-search-input"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      <Table columns={getColumns()} dataSource={clients} />
    </div>
  );
}

ClientsList.propTypes = {
  ...withRouter,
  clients: PropTypes.array,
};

ClientsList.defaultProps = {
  clients: [],
};

export default ClientsList;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import * as Dialog from '../Shared/Dialog';
import * as PropTypes from '../../common/proptypes';

// store imports
import { $fetchClientsDemands } from './state';

// components imports
import DemandsList from './DemandsList';

// image imports
import MercedesLogo from '../../assets/MercedesLogo.png';
import CitroenLogo from '../../assets/citroen.png';
import BMWLogo from '../../assets/BMWLogo.png';
import AudiLogo from '../../assets/audi.png';


const withStore = connect((state) => ({
  processing: state.Activity.processing,
  clientsDemands: state.FrontOffice.clientsDemands,
}));

const Wrapper = (C) => withStore(C);


const { Content } = Layout;

/* -------------------------------- mock data ------------------------------- */
const demands = [
  {
    name: 'Mehdi Mahmoudi',
    vin: ['--', '2009AS05284', '--'],
    carBrand: [
      { name: 'Mercedes', image: MercedesLogo },
      { name: 'Citroen', image: CitroenLogo },
      { name: 'Audi', image: AudiLogo },
    ],
    carPieces: [
      'Plaquette de frein, Disque frein,Plaquette de frein',
      'Filtre à huile, Disque frein,Plaquette de frein',
      'Rotule de direction, Plaquette de frein',
    ],
    update: ['31/5/2018', '31/5/2018', '31/5/2018'],
    statusCompleted: [false, true, false],
  },
  {
    name: 'Andy Jackson',
    carBrand: [{ name: 'Mercedes', image: MercedesLogo }],
    vin: ['2009AS05284'],
    carPieces: ['Filtre à carburant, Étrier de frein'],
    update: ['31/5/2018'],
    statusCompleted: [true],
  },
  {
    name: 'Chris Thomas',
    vin: ['2009sqddf5284'],
    carBrand: [{ name: 'BMW', image: BMWLogo }],
    carPieces: ['Rétroviseur exterieur, Embrayage'],
    update: ['31/5/2018'],
    statusCompleted: [false],
  },
];

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
function DemandsListView(props) {
  const { dispatch, clientsDemands } = props;

  // This is when the data isn't ready yet (REQUEST) or in case of a fetch failure
  const validateDemands = Array.isArray(clientsDemands) ? clientsDemands : demands;

  useEffect(() => {
    dispatch($fetchClientsDemands()).catch((error) => Dialog.toast(Dialog.FAILURE, error.message));
  }, []);

  return (
    <Content>
      <DemandsList demands={validateDemands} />
    </Content>
  );
}

DemandsListView.propTypes = {
  ...PropTypes.withState,
  clientsDemands: PropTypes.array.isRequired,
};

export default Wrapper(DemandsListView);

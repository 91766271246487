import { API_ENDPOINT } from '../../common/config';

import * as FetchHelper from '../../common/fetch.helper';
import * as StateHelper from '../../common/state.helper';

import * as Activity from '../Shared/Activity';
import { $reset } from '../Shared/Activity.state';

/**
 * Module name
 */

export const MODULE = 'FrontOffice';

const INITIAL_STATE = {
  clientsDemands: [],
};

/**
 * Reset
 */
export const $resete = StateHelper.createSimpleOperation(MODULE, 'reset', () => $reset.action());

/**
 * Fetch Clients Demands
 */
export const $fetchClientsDemands = StateHelper.createAsyncOperation(MODULE, 'fetchClientsDemands', () => {
  return async (dispatch) => {
    Activity.processing(MODULE, $fetchClientsDemands.NAME);
    dispatch($fetchClientsDemands.request());

    try {
      const response = await fetch(`${API_ENDPOINT}/clients`);
      const result = await FetchHelper.ResponseHandler(response);
      return dispatch($fetchClientsDemands.success(result));
    } catch (error) {
      await FetchHelper.ErrorValueHandler(error);
      dispatch($fetchClientsDemands.failure(error));
    } finally {
      Activity.done(MODULE, $fetchClientsDemands.NAME);
    }
  };
});

/**
 * Create a client Demand
 */
export const $createClientsDemand = StateHelper.createAsyncOperation(MODULE, 'createClientsDemand', (payload) => {
  return async (dispatch) => {
    Activity.processing(MODULE, $createClientsDemand.Name);
    dispatch($createClientsDemand.request());

    try {
      const response = await fetch(`${API_ENDPOINT}/clients`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const result = await FetchHelper.ResponseHandler(response);
      return dispatch($createClientsDemand.success(result));
    } catch (error) {
      await FetchHelper.ErrorValueHandler(error);
      dispatch($createClientsDemand.failure(error));
    } finally {
      Activity.done(MODULE, $createClientsDemand.NAME);
    }
  };
});

/**
 * this function adapats the response of the api to the data needed in the webapp
 * @param {object} resp represents the rsespone of the api
 */
const responseAdapter = (resp) => {
  const arr = Object.values(resp);
  arr.pop();
  return arr;
};

/**
 * Reducer
 */
export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case $reset.ACTION:
      return INITIAL_STATE;
    case $fetchClientsDemands.REQUEST:
      return {
        ...state,
        clientsDemands: null,
      };
    case $fetchClientsDemands.SUCCESS:
      return {
        ...state,
        clientsDemands: responseAdapter(action),
      };
    case $fetchClientsDemands.FAILURE:
      return {
        ...state,
        clientsDemands: null,
      };
    default:
      return state;
  }
}

/**
 * State
 *
 * This module is for state definition
 */

import * as Activity from '../topics/Shared/Activity.state';
import * as Shared from '../topics/Shared/state';
import * as Auth from '../topics/Auth/state';
import * as Session from '../topics/Session/state';
import * as Home from '../topics/Home/state';
import * as FrontOffice from '../topics/FrontOffice/state';
import * as BackOffice from '../topics/BackOffice/state'

const $state = {
  Activity,
  Shared,
  Auth,
  Session,
  Home,
  FrontOffice,
  BackOffice,
};

export default $state;

// library imports
import React, { useState } from 'react';
import {
  Icon, Avatar, Menu, Layout,
} from 'antd';
import { Link } from 'react-router-dom';

// components imports
import GeneralInfoView from './GeneralInfoView';
import CarPiecesView from './CarPiecesView';
import StatisticsView from './StatisticsView';

// style import
import './index.css';

// antd components
const { Content } = Layout;

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
function ClientInformation() {
  const [selectedMenuItem, setSelectedMenuItem] = useState('generalInfo');

  const onSelectMenuItem = (e) => {
    setSelectedMenuItem(e.key);
  };

  // eslint-disable-next-line consistent-return
  const componentsSwtich = (key) => {
    switch (key) {
      case 'generalInfo':
        return <GeneralInfoView />;
      case 'carPieces':
        return <CarPiecesView />;
      case 'statistics':
        return <StatisticsView />;
      default:
        break;
    }
  };

  return (
    <div className="vortex-crm-client-info">
      <div className="vortex-crm-clients-info-navigation">
        <div>
          <Avatar size={64} src="https://randomuser.me/api/portraits/men/41.jpg" />
          <h1>Andy Jackson</h1>
        </div>
        <div>
          <Link to="/crm">
            <Icon type="arrow-left" className="vortex-provider-demand-details-back-arrow" />
          </Link>
        </div>
      </div>
      <Menu selectedKeys={selectedMenuItem} mode="horizontal" onClick={(e) => onSelectMenuItem(e)}>
        <Menu.Item key="generalInfo">Informations générales</Menu.Item>
        <Menu.Item key="carPieces">Piéces auto</Menu.Item>
        <Menu.Item key="statistics">Statistiques</Menu.Item>
      </Menu>
      <Content className="vortex-crm-client-info-content">{componentsSwtich(selectedMenuItem)}</Content>
    </div>
  );
}

export default ClientInformation;

// library imports
import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import * as PropTypes from '../../common/proptypes';

// component imports
import ClientsList from './ClientsList';

// redux store link
const withStore = connect((state) => ({
  processing: state.Activity.processing,
  clients: state.clientsList.clients,
}));

const Wrapper = (C) => withStore(C);

// antd component
const { Content } = Layout;

// mock data
const mockClients = [
  {
    client: { name: 'Andy Jackson', image: 'https://randomuser.me/api/portraits/men/41.jpg' },
    registrationNumber: '09837-DE',
    info: 'Ut vel posuere dui. In hac habitasse platea dictumst. Sed quis justo eleifend dui convallis ',
    addingDate: '25/06/2019',
  },
  {
    client: { name: 'Aous Michel', image: 'https://randomuser.me/api/portraits/men/90.jpg' },
    registrationNumber: '09837-DE',
    info: 'Ut vel posuere dui. In hac habitasse platea dictumst. Sed quis justo eleifend dui convallis ',
    addingDate: '25/06/2019',
  },
  {
    client: { name: 'Jhonny Deep', image: 'https://randomuser.me/api/portraits/men/79.jpg' },
    registrationNumber: '09837-DE',
    info: 'Ut vel posuere dui. In hac habitasse platea dictumst. Sed quis justo eleifend dui convallis ',
    addingDate: '25/06/2019',
  },
  {
    client: { name: 'Howard jackson', image: 'https://randomuser.me/api/portraits/men/71.jpg' },
    registrationNumber: '09837-DE',
    info: 'Ut vel posuere dui. In hac habitasse platea dictumst. Sed quis justo eleifend dui convallis ',
    addingDate: '25/06/2019',
  },
  {
    client: { name: 'Phill Slims', image: 'https://randomuser.me/api/portraits/men/59.jpg' },
    registrationNumber: '09837-DE',
    info: 'Ut vel posuere dui. In hac habitasse platea dictumst. Sed quis justo eleifend dui convallis ',
    addingDate: '25/06/2019',
  },
];

function DemandsListView(props) {
  const { clients } = props;

  // This is when the data isn't ready yet (REQUEST) or in case of a fetch failure
  const validateclients = Array.isArray(clients) ? clients : mockClients;

  // the fetching from the store isn't ready yet
  // useEffect(() => {
  //   dispatch($fetchDemands()).catch((error) => Dialog.toast(Dialog.FAILURE, error.message));
  // }, []);

  return (
    <Content>
      <ClientsList clients={validateclients} />
    </Content>
  );
}

DemandsListView.propTypes = {
  ...PropTypes.withState,
  clients: PropTypes.array,
};

DemandsListView.defaultProps = {
  clients: [],
};

export default Wrapper(DemandsListView);

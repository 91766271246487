import React, { useState } from 'react';
import {
  Avatar, Table, Button, Input, Icon, DatePicker,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as PropTypes from '../../common/proptypes';

// style imports
import './index.css';

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
function DemandsList(props) {
  const { demands } = props;
  const dateFormatList = ['DD/MM/YYYY'];
  const [setSearchText] = useState('');

  const onDateChange = (date) => {
    return date;
  };

  // Header columns definition
  const getColumns = () => [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div style={{ display: 'flex' }}>
          <Avatar
            style={{ backgroundColor: '#522F93', verticalAlign: 'middle', margin: '30px 10px 0px 0px' }}
            size="large"
          >
            V
          </Avatar>
          <h4 className="vortex-front-office-demands-table-client-name">{text}</h4>
        </div>
      ),
    },
    {
      title: 'Mise à jour',
      dataIndex: 'update',
      key: 'update',
      render: (data) => (
        <div style={{ marginTop: 30 }}>
          {data.map((text) => (
            <div className="vortex-front-office-demands-table-client-date">{text}</div>
          ))}
        </div>
      ),
    },
    {
      title: 'VIN',
      dataIndex: 'vin',
      key: 'vin',
      render: (data) => (
        <div style={{ marginTop: 30 }}>
          {data.map((text) => (
            <h4 className="vortex-front-office-demands-table-client-vin">{text}</h4>
          ))}
        </div>
      ),
    },
    {
      title: 'Marque de la voiture',
      dataIndex: 'carBrand',
      key: 'carBrand',
      filters: [
        {
          text: 'Mercedes',
          value: 'Mercedes',
        },
        {
          text: 'BMW',
          value: 'BMW',
        },
        {
          text: 'Audi',
          value: 'Audi',
        },
      ],
      onFilter: (value, record) => record.carBrand === value,
      render: (data) => (
        <div style={{ marginTop: 30 }}>
          {data.map((text) => (
            <div>
              {text === '' ? (
                '--'
              ) : (
                <div className="vortex-front-office-demands-table-car-name">
                  <Avatar src={text.image} />
                  <h4 className="vortex-front-office-demands-table-car-brandName">{text.name}</h4>
                </div>
              )}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Piéces de la voiture',
      dataIndex: 'carPieces',
      key: 'carPieces',
      render: (data) => (
        <div style={{ marginTop: 30 }}>
          {data.map((text) => (
            <div className="vortex-front-office-demands-table-client-car-pieces">{text}</div>
          ))}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'statusCompleted',
      key: 'statusCompleted',
      render: (data) => (
        <div className="vortex-front-office-demands-table-client-status">
          {data.map(() => (
            <div className="vortex-provider-demands-table-buttons">
              <Button className="vortex-provider-demands-buttons-delete">Supprimer</Button>
              <Link to="/provider/details">
                <Button className="vortex-provider-demands-buttons-accepte">Accepter</Button>
              </Link>
            </div>
          ))}
        </div>
      ),
    },
  ];

  return (
    <div className="vortex-front-office-demands">
      <div className="vortex-front-office-demands-navigation">
        <h1>Listes des demandes</h1>
        <div>
          <DatePicker
            defaultValue={moment(new Date())}
            format={dateFormatList}
            onChange={onDateChange}
          />
          <Input
            placeholder="Recherche ici..."
            prefix={<Icon type="search" />}
            className="vortex-front-office-demands-navigation-search-input"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      <Table columns={getColumns()} dataSource={demands} />
    </div>
  );
}

DemandsList.propTypes = {
  demands: PropTypes.array,
};

DemandsList.defaultProps = {
  demands: [],
};

export default DemandsList;

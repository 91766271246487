import { API_ENDPOINT } from '../../common/config';

import * as FetchHelper from '../../common/fetch.helper';
import * as StateHelper from '../../common/state.helper';

import * as Activity from '../Shared/Activity';
import { $reset } from '../Shared/Activity.state';

/**
 * Module name
 */

export const MODULE = 'BackOffice';

const INITIAL_STATE = {
  providers: [],
};

/**
 * Reset
 */
export const $resete = StateHelper.createSimpleOperation(MODULE, 'reset', () => $reset.action());

/**
 * Fetch Providers
 */
export const $fetchProviders = StateHelper.createAsyncOperation(MODULE, 'fetchProviders', () => {
  return async (dispatch) => {
    Activity.processing(MODULE, $fetchProviders.NAME);
    dispatch($fetchProviders.request());

    try {
      const response = await fetch(`${API_ENDPOINT}/providers`);
      const result = await FetchHelper.ResponseHandler(response);
      return dispatch($fetchProviders.success(result));
    } catch (error) {
      await FetchHelper.ErrorValueHandler(error);
      dispatch($fetchProviders.failure(error));
    } finally {
      Activity.done(MODULE, $fetchProviders.NAME);
    }
  };
});

/**
 * this function adapats the response of the api to the data needed in the webapp
 * @param {object} resp represents the rsespone of the api
 */
const responseAdapter = (resp) => {
  const arr = Object.values(resp);
  arr.pop();
  return arr;
};

/**
 * Reducer
 */
export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case $reset.ACTION:
      return INITIAL_STATE;
    case $fetchProviders.REQUEST:
      return {
        ...state,
        providers: null,
      };
    case $fetchProviders.SUCCESS:
      return {
        ...state,
        providers: responseAdapter(action),
      };
    case $fetchProviders.FAILURE:
      return {
        ...state,
        providers: null,
      };
    default:
      return state;
  }
}
